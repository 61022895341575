module.exports = [{
      plugin: require('/vercel/path0/node_modules/gatsby-plugin-catch-links/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('/vercel/path0/node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Eightyeightbits","short_name":"88bits","start_url":"/","background_color":"#000000","theme_color":"#000000","display":"minimal-ui","icon":"src/images/favicon-144.png"},
    },{
      plugin: require('/vercel/path0/node_modules/gatsby-plugin-netlify-cms/gatsby-browser.js'),
      options: {"plugins":[],"enableIdentityWidget":false},
    },{
      plugin: require('/vercel/path0/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
